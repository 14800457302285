@import "./global.scss";
.app {
    height: 100vh;
    .sections {
        width: 100%;
        height: calc(100vh - 56px);
        // @include sm {
        //     height: calc(100vh - 48px);
        // }
        @include sm {
            height: calc(100vh - 64px);
        }
        position: relative;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        > * {
            width: 100vw;
            height: calc(100vh - 56px);
            scroll-snap-align: start;
            // @include sm {
            //     height: calc(100vh - 48px);
            // }
            @include sm {
                height: calc(100vh - 64px);
            }
        }
    }
}
