.contact {
    background-color: #576f72;
}
.light {
    h2 {
        color:#F0EBE3
    }
    span {
        color: #e4dccf;
    }
}
