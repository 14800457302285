$mainColor: #000;

$width: 768px;
$screen-xs-min: 0px;
$screen-sm-min: 600px;

 // Medium devices
 @mixin xs {
    @media (min-width: #{$screen-xs-min}) {
        @content;
    }
 }
@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
 }
 
